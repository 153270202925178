
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';

import {
  initializeMap,
  addGroup,
  dropMarker,
  resizeMap,
  drawRoute,
  clearGroup,
} from '@twogo/here-maps/src/lib/HereMapsService';

export default Vue.extend({
  name: 'MoleculeHereMap',
  props: {
    center: {
      type: Object as PropType<CoordinatesModel>,
      default() { return { lat: 0, lng: 0 }; },
    },
    markers: {
      type: Array as PropType<BaseMarkerModel[]>,
      default: () => [],
    },
    routes: {
      type: Array as PropType<RouteModel[]>,
      default: () => [],
    },
    onMarkerDrag: {
      type: Function as PropType<(c: any, markerId: string) => void>,
      required: false,
    },
    zoom: {
      type: Number,
      default: 10,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
    classList: {
      type: String,
      default: '',
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    map: {} as any,
    behavior: {} as any,
    group: {} as any,
  }),
  mounted() {
    const { map, behavior, ui } = initializeMap(this.$refs.hereMap, this.center, this.zoom);

    if (typeof ui === 'object' && this.disableButtons) {
      ui.getControl('mapsettings').setVisibility(false);
      ui.getControl('zoom').setVisibility(false);
      ui.getControl('scalebar').setVisibility(false);
      // TODO: Move this to the Here maps Service maybe?
      behavior.disable(window.H.mapevents.Behavior.Feature.WHEEL_ZOOM);
      behavior.disable(window.H.mapevents.Behavior.Feature.PANNING);
      behavior.disable(window.H.mapevents.Behavior.Feature.DBL_TAP_ZOOM);
    }
    this.map = map;
    this.behavior = behavior;
    this.group = addGroup(this.map);

    if (this.markers.length) {
      this.drawMarkersOnMap();
    }
    if (this.routes.length) {
      this.addPolyLineOnMap();
    }

    this.$emit('mounted', map, this.group);
  },
  methods: {
    drawMarkersOnMap() {
      this.markers.forEach((marker) => {
        dropMarker(this.map, this.group, marker, this.behavior, {
          dragable: !!this.onMarkerDrag,
          onMarkerDragEnd: this.onMarkerDrag,
        });
      });

      if (this.markers.length === 1 && !this.routes.length) {
        resizeMap(this.map, this.group, 16);
      }
      this.$emit('markersDrop');
    },
    updateMarkersPosition(newProps: BaseMarkerModel[], oldProps: BaseMarkerModel[]) {
      const arePropsEqual = (x, y) => _(x).differenceWith(y, _.isEqual).isEmpty();
      if (newProps.length === oldProps.length && arePropsEqual(newProps, oldProps)) {
        // Case when old and new props are the same.
        return;
      }
      clearGroup(this.group);
      this.drawMarkersOnMap();
    },
    addPolyLineOnMap() {
      this.routes.forEach((route) => {
        drawRoute(this.group, route);
      });
      resizeMap(this.map, this.group);
      this.$emit('polyLineDrop');
    },
  },
  watch: {
    markers: 'updateMarkersPosition',
    routes: 'addPolyLineOnMap',
  },
});
